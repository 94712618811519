<template>
  <v-container>
    <h2>Advisers</h2>
    <v-list>
      <template v-for="(adviser, index) in advisers">
        <v-list-item
          :key="adviser.uid"
          :to="{name:'AdviserEdit', params: {uid:adviser.uid}}"
          link
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ adviser.auth.displayName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              #{{ index + 1 }} {{ adviser.auth.email }} {{ adviser.auth.phone }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-btn color="secondary" @click="$router.push({name:'AdviserAdd'})">ADD ADVISER</v-btn>
  </v-container>
</template>

<script>
import { advisersList, getCurrentUserId } from '@/services/firebase';

export default {
  name: 'AdvisersIndex',

  data() {
    return {
      currentUserId: null,
      advisers: [],
    };
  },

  async mounted() {
    this.currentUserId = await getCurrentUserId();

    advisersList()
      .then((response) => {
        response.forEach((u) => {
          const record = {
            uid: u.id,
            ...u.data(),
          };
          if (record.uid !== this.currentUserId) {
            this.advisers.push(record);
          }
        });
      });
  },
};
</script>
