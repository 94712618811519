var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('h2', [_vm._v("Advisers")]), _c('v-list', [_vm._l(_vm.advisers, function (adviser, index) {
    return [_c('v-list-item', {
      key: adviser.uid,
      attrs: {
        "to": {
          name: 'AdviserEdit',
          params: {
            uid: adviser.uid
          }
        },
        "link": ""
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(adviser.auth.displayName) + " ")]), _c('v-list-item-subtitle', [_vm._v(" #" + _vm._s(index + 1) + " " + _vm._s(adviser.auth.email) + " " + _vm._s(adviser.auth.phone) + " ")])], 1)], 1)];
  })], 2), _c('v-btn', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'AdviserAdd'
        });
      }
    }
  }, [_vm._v("ADD ADVISER")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }